<template>
  <el-transfer
    style="text-align: left; display: inline-block"
    v-model="_value"
    filterable
    :props="{
      key: 'id',
      label: 'username',
    }"
    :titles="['未分组', rightGourpsTitle]"
    :format="{
      noChecked: '${total}',
      hasChecked: '${checked}/${total}',
    }"
    :data="options"
  >
  </el-transfer>
</template>

<script>
export default {
  props: {
    rightGourpsTitle: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: () => [],
    },
    activeId: {
      type: [String, Number],
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    options() {
      return this.data.filter(
        (item) =>
          !item.group || parseInt(item.group.id) === parseInt(this.activeId)
      );
    },
  },
  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>
<style lang="scss">
.el-transfer-panel {
  width: 240px;
}

.el-transfer-panel__body {
  weight: 240px;
  height: 300px;
}

.el-transfer-panel__list {
  weight: 240px !important;
  height: 260px !important;
}
</style>
