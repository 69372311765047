<template>
  <Dialog
    title="分组配置"
    confirmText="保 存"
    custom-class="group-manage-dialog"
    width="1200px"
    height="600px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <div class="h-full" ref="mainbox" v-loading="loading">
      <div ref="actionbox" class="pb-3">
        <el-input
          type="text"
          v-model="newGroupName"
          placeholder="新分组名称"
          class="w-60"
        ></el-input
        ><el-button type="success" class="ml-2" @click="handleCreateGroup"
          >创建分组</el-button
        >
      </div>
      <div
        class="
          flex
          justify-items-start
          items-start
          border-solid border border-gray-200
        "
        style="height: calc(100% - 45px)"
      >
        <ul
          class="w-60 h-full border-solid border-r border-gray-200"
          style="overflow: hidden; overflow-y: auto"
        >
          <template v-if="groupsList.length">
            <li
              @click.stop="handleActiveGroups(item)"
              :class="`${
                activeID === item['id'] ? 'bg-gray-100 text-blue-500' : ''
              } p-1 border-solid border-b flex justify-between items-center cursor-pointer`"
              v-for="(item, index) in groupsList"
              :key="index"
            >
              {{ item["name"] }}
              <el-button
                class="text-red-600"
                type="text"
                @click="handleDelGroup(item['id'])"
                >删 除</el-button
              >
            </li>
          </template>
          <li v-else class="text-center py-10 text-gray-400">暂无分组</li>
        </ul>

        <div class="flex-1 h-full flex justify-center items-center">
          <Transfer
            v-model="groupStudents"
            :activeId="activeID"
            :data="waitSelected"
            :right-gourps-title="rightGroupsTitle"
          ></Transfer>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
import Transfer from "./transfer";
export default {
  name: "",
  components: { Dialog, Transfer },
  props: {},
  data() {
    return {
      loading: false,
      newGroupName: "",
      activeID: 0,
      visible: false,
      rightGroupsTitle: "默认",
      groupsList: [],

      waitSelected: [], // 待选项
      groupStudents: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 打开配置接口
    async open() {
      Object.assign(this.$data, this.$options.data());
      this.visible = true;
      await this.getGroupLists(); // 获取分组列表
      await this.getToBeSelected(); // 获取待选项
    },

    // 获取组列表
    async getGroupLists() {
      this.loading = true;
      await this.$cloud
        .get("/group/list", {
          roomId: this.$route.query["id"],
          pageIndex: 1,
          pageSize: 999,
        })
        .then(async (res) => {
          this.loading = false;
          this.groupsList = res.list;
          if (this.groupsList.length) {
            this.activeID = this.groupsList[0]?.id;
            await this.getGroupStedents();
            this.rightGroupsTitle = this.groupsList[0]?.name;
          }
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },

    // 创建新的分组
    handleCreateGroup() {
      if (!this.newGroupName) {
        this.$message.error("请输入新创建组名！");
        return false;
      }
      this.loading = true;
      this.$cloud
        .post("/group/create", {
          roomId: this.$route.query["id"],
          name: this.newGroupName,
        })
        .then(() => {
          this.newGroupName = "";
          this.loading = false;
          this.getGroupLists();
          this.$message.success("创建成功！");
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // 删除分组
    handleDelGroup(id) {
      this.$confirm("确定删除该分组吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .get("/group/delete", { id })
          .then(() => {
            this.$message.success("删除成功！");
            this.getGroupLists();
            this.loading = false;
          })
          .catch((err) => {
            this.$message.error(err);
            this.loading = false;
          });
      });
    },

    // 获取待选项
    async getToBeSelected() {
      await this.$cloud
        .get("/student/list", {
          roomId: this.$route.query["id"],
          pageIndex: 1,
          pageSize: 999,
        })
        .then((res) => {
          this.waitSelected = res.list;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 获取组内成员
    async getGroupStedents() {
      await this.$cloud
        .get("/group/detail", { id: this.activeID })
        .then((res) => {
          this.groupStudents = res.student.map((item) => item.id);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    handleActiveGroups(item) {
      this.groupStudents = [];
      this.rightGroupsTitle = item["name"];
      this.activeID = item["id"];
      this.getGroupStedents();
    },

    handleConfirm() {
      if (!this.activeID) {
        this.$message.error("请先创建分组，再分配学生");
        return;
      }
      this.$confirm("确定保存当前设置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .post("/group/updatestudent", {
            groupId: this.activeID,
            studentIds: this.groupStudents,
          })
          .then(async () => {
            this.$message.success("保存成功！");
            this.$emit("refresh");
            // this.visible = false;
            this.loading = false;
            await this.getToBeSelected(); // 获取待选项
          })
          .catch((err) => {
            this.$message.error(err);
            this.loading = false;
          });
      });
    },
  },
};
</script>
<style lang="scss">
.group-manage-dialog {
  .el-dialog__body {
    padding: 10px;
  }
}
</style>
