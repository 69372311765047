<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-04-26 22:58:00
 * @LastEditors:
 * @LastEditTime: 2023-04-26 22:58:00
-->
<template>
  <div v-loading="loading">
    <!-- 打印成绩单 -->
    <canvas v-show="false" id="mycanvas"></canvas>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="!userinfo.no">
      <el-breadcrumb-item :to="{ path: '/pages/home/home' }"
        >首页
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <Main
      ref="main"
      class="h-full"
      :is-page="false"
      :columns="columns"
      :is-index="true"
      :is-selection="false"
      api="/evaluation/student_list"
      :table-data="tableList"
      :on-params="onParams"
      :on-result="onResult"
    >
      <template #action>
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="flex-1 min-w-0">
            <h2
              class="
                text-2xl
                font-bold
                leading-7
                text-gray-900
                sm:text-3xl sm:truncate
              "
            >
              {{ title }}学生列表
            </h2>
            <div
              class="
                mt-1
                flex flex-col
                sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6
              "
            >
              <div class="mt-2 flex items-center text-sm text-gray-500">
                选择不同的学生，点击评分按钮可对相应的学生进行评分，班主任可以设置学生分组，设置不同的互评模式
              </div>
            </div>
          </div>
          <div class="mt-5 flex lg:mt-0 lg:ml-4">
            <span
              >{{ classinfo.semester_year }}年第{{
                classinfo.semester
              }}学期</span
            >
            <span class="sm:ml-3" v-if="!isMaster && !userinfo.no">
              任课：{{ subjectsName }}
            </span>
          </div>

          <div class="mt-5 flex lg:mt-0 lg:ml-4" v-if="isMaster">
            <span class="hidden sm:block ml-3">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-gray-700
                  bg-white
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
                @click="handleExportReport"
              >
                导出汇总表
              </button>
            </span>
            <span class="sm:ml-3">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-indigo-600
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
                @click="handleSetGroups"
              >
                设置分组
              </button>
            </span>
            <span class="sm:ml-3">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-blue-500
                  hover:bg-blue-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
                @click="handleHistoryGrades"
              >
                历史成绩
              </button>
            </span>
          </div>
        </div>
      </template>
      <template #search>
        <div class="flex justify-between">
          <Search
            v-model="params"
            :options="searchOptions"
            @onSearch="handleOnSearch"
          ></Search>
          <div v-if="!userinfo.no">
            <el-button type="success" @click="handleExportElxs"
              >导出评分模板
            </el-button>
            <el-upload
              action=""
              :show-file-list="false"
              style="display: contents"
              accept=".xls,.XLS,.xlsx,.XLSX"
              :http-request="httpRequest"
            >
              <el-button icon="el-icon-upload2" class="ml-1"
                >导入评分
              </el-button>
            </el-upload>
            <el-button v-if="isMaster" class="ml-1" @click="handlePrint"
              >打印反馈表
            </el-button>
            <el-button
              v-if="isMaster"
              style="margin-left: 0.25rem"
              @click="handleCheckScore"
            >
              查看评分情况
            </el-button>
            <el-button
              v-if="isMaster"
              style="margin-left: 0.25rem"
              @click="handlePrintMutualAll()"
            >
              一键打印互评表
            </el-button>
          </div>
          <div v-if="userinfo.no">
            <el-button type="success" @click="handleExportStudentElxs"
              >导出评分模板
            </el-button>
            <el-upload
              action=""
              :show-file-list="false"
              style="display: contents"
              accept=".xls,.XLS,.xlsx,.XLSX"
              :http-request="httpRequestStudent"
            >
              <el-button icon="el-icon-upload2" class="ml-1"
                >导入评分
              </el-button>
            </el-upload>
          </div>
        </div>
      </template>
      <template #table-header-rate="{ header }">
        <el-checkbox
          true-label="YES"
          false-label="NO"
          v-model="header['rate']"
          @change="handleChangeAllIscore"
        ></el-checkbox>
        {{ header["label"] }}
      </template>
      <template #table-item-hasEval="{ row }">
        <template v-if="row['hasEval'] === 'YES'">
          <el-tag type="success">已评分</el-tag>
        </template>
      </template>
      <template #table-item-group="{ row }">
        {{ row["group"] ? row["group"].name : "" }}
      </template>
      <template #table-item-rate="{ row }">
        <el-checkbox
          true-label="YES"
          false-label="NO"
          v-model="row['rate']"
          @change="handleChangeIscore($event, row)"
        ></el-checkbox>
      </template>
      <template #table-item-action="{ row, $index }">
        <el-button
          v-if="
            isEditScore &&
            row['inDate'] &&
            userinfo.no &&
            userinfo.no === row['no'] &&
            row['hasEval'] === 'NO'
          "
          type="text"
          style="color: #ff4d51"
          @click="handleStedentScore(row, $index)"
          >自评
        </el-button>
        <el-button
          v-if="
            isEditScore &&
            row['inDate'] &&
            userinfo.no &&
            userinfo.no !== row['no'] &&
            row['hasEval'] === 'NO'
          "
          type="text"
          @click="handleStedentScore(row, $index)"
          >互评
        </el-button>
        <el-button
          v-if="
            isEditScore &&
            row['inDate'] &&
            !userinfo.no &&
            (isMaster || row['hasEval'] === 'NO')
          "
          type="text"
          @click="handleScore(row)"
          >评分
        </el-button>
        <el-button
          type="text"
          v-if="isShowAppeal(row)"
          @click="handleAppeal(row)"
          >申诉
        </el-button>
        <!--                去除学生改分功能-->
        <el-button
          type="text"
          v-if="row['hasEval'] === 'YES' && userinfo.no"
          @click="handleEditGrades(row, $index)"
          >修改
        </el-button>
        <el-button
          type="text"
          v-if="row['hasEval'] === 'YES'"
          @click="handleCheckGrades(row, $index)"
          >查看
        </el-button>
        <el-button
          type="text"
          v-if="isMaster"
          @click="handleCheckFraction(row, $index)"
        >
          查分
        </el-button>
      </template>
    </Main>

    <!-- 打印互评表  -->
    <div
      v-show="true"
      ref="printMutualTable"
      class="p-1"
      v-for="(data, i) in tableData"
      :key="i"
    >
      <div class="text-center text-xl py-3">
        {{ data.username }}学生打互评明细表
      </div>
      <el-table
        class="print-table-height"
        :data="data.recordList"
        border
        :cell-style="{
          borderColor: '#000',
          borderWidth: '1px',
          borderStyle: 'solid',
        }"
        :header-cell-style="{
          borderColor: '#000',
          borderWidth: '1px',
          borderStyle: 'solid',
        }"
      >
        <el-table-column type="index" align="center" label="#" width="60">
        </el-table-column>
        <el-table-column
          v-for="(key, mutualIndex) in Object.keys(mutualColumns)"
          :prop="key"
          :key="mutualIndex"
          :label="mutualColumns[key]"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="text-right pr-40 py-6">签名：</div>
    </div>

    <modifyPassword ref="modify"></modifyPassword>
    <SetGroups ref="groups" @refresh="onRefresh"></SetGroups>
    <Scoring ref="scoring" @refresh="onRefresh" :roomId="roomId"></Scoring>
    <StedentScore
      ref="stedentScore"
      @refresh="onRefresh"
      :roomId="roomId"
      :list="results.list"
      :class-info="classinfo"
    ></StedentScore>
    <TeacherScoreInfo
      ref="teacherScoreInfo"
      :roomId="roomId"
    ></TeacherScoreInfo>
    <CheckScores
      ref="checkScores"
      :roomId="roomId"
      :class-name="title"
    ></CheckScores>
    <Result ref="result"></Result>
    <!--    <Statistics ref="statistics"></Statistics>-->
    <Appeal ref="appeal" :userinfo="userinfo" :roomId="roomId"></Appeal>
    <historyGrades
      ref="historyGrades"
      :classId="[classinfo.level, roomId]"
      :class-name="title"
    ></historyGrades>
  </div>
</template>
<script>
import Main from "../components/main";
import Search from "../components/search";
import SetGroups from "./components/groups.vue";
import Scoring from "./components/scoring.vue";
import StedentScore from "./components/stedentScore.vue";
import TeacherScoreInfo from "./components/teacherScoreInfo.vue";
import CheckScores from "./components/checkScores.vue";
import { columns, searchOptions } from "./config";
import Result from "./components/result.vue";
import historyGrades from "./components/historyGrades.vue";
import mixins from "@/util/mixin.js";
// import Statistics from "./components/statistics.vue";
import Appeal from "./components/appeal.vue";
import xlsx from "xlsx";
import { export_json_to_excel } from "@/util/Export2Excel.js";
import modifyPassword from "@/pages/select/modifyPassword.vue";

export default {
  name: "",
  components: {
    Main,
    SetGroups,
    Search,
    Scoring,
    StedentScore,
    TeacherScoreInfo,
    CheckScores,
    historyGrades,
    Result,
    Appeal,
    modifyPassword,
  },
  props: {},
  mixins: [mixins],
  data() {
    return {
      columns,
      searchOptions,
      Appeal,
      title: "",
      api: "",
      loading: false, //加载loading
      results: [], // 查询结果
      isMaster: false, //是否为班主任
      userinfo: {}, // 登录账号信息
      classinfo: {}, // 班级信息
      subjectsName: "", // 任课科目
      roomId: "", // 班级id
      params: {
        name: "",
        pageSize: 999,
        pageIndex: 1,
      },

      mutualColumns: {
        sno: "学号",
        username: "姓名",
        A1: "维度1",
        A2: "维度2",
        A3: "维度3",
        A4: "维度4",
        A5: "维度5",
      },
      tableData: [], // 互评表信息

      tableList: [], //  表格数据
      scoreList: [], //  评分列表

      isEditScore: true, // 判断是否在评分任务周期内
    };
  },
  created() {},
  async mounted() {
    await this.handleGetStudents();
  },
  methods: {
    // 判断是否在评分周期内
    async getScoreTimeRange() {
      let res = await this.$cloud.get("/evaluation/detail_by_room_v2", {
        roomId: this.roomId,
      });
      this.isEditScore = !!(res.dimensions && res.dimensions.length);
    },

    /**
     *  @actions:  是否可申诉
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 22:06
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    isShowAppeal(row) {
      if (this.isMaster) {
        return !row["inDate"];
      } else {
        return (
          (this.userinfo.role === "admin" ||
            this.userinfo.role === "teacher") &&
          !row["is_edit"] &&
          row["hasEval"] === "YES"
        );
      }
    },

    // 列表参数
    onParams() {
      return this.params;
    },

    // 列表结果
    onResult(datas) {
      this.results = datas;
      // // 当登录人为班主任时，方才设置默认评分权限
      if (this.isMaster) {
        this.setAllRate("YES");
        for (let i = 0; i < datas["list"].length; i++) {
          let item = datas["list"][i];
          if (!item["rate"] || item["rate"] !== "YES") {
            this.setAllRate("NO");
            break;
          }
        }
      }

      this.tableData = datas["list"];
      //  合并评分数据
      if (!this.userinfo.no) {
        // 教师合并评分数据
        this.tableList = datas["list"].map((item) => {
          let arrays = this.scoreList[item.id];
          let obj = {};
          if (arrays && arrays.length > 0) {
            arrays.map((i) => {
              obj[i.no] = i.score;
            });
          }
          return {
            ...item,
            ...obj,
          };
        });
      } else {
        console.log("分数列表：", this.scoreList);
        this.tableList = datas["list"].map((item) => {
          let arrays = this.scoreList[item.id];
          if (arrays) {
            let obj = {};
            Object.values(arrays).map((i) => {
              obj[i?.no] = i?.score;
            });
            return {
              ...item,
              ...obj,
            };
          } else {
            return {
              ...item,
            };
          }
        });
        // 学生合并评分数据
        console.log("datas::", datas);
      }
      console.log("分数list", this.tableList);
    },

    // 设置默认评分权限
    setAllRate(ret) {
      this.$nextTick(() => {
        this.columns.map((item, index) => {
          if (item.field === "rate") {
            this.columns.splice(index, 1, {
              field: "rate",
              label: "评分",
              align: "center",
              width: "120",
              fixed: "right",
              rate: ret,
            });
          }
        });
        this.key = new Date().getTime();
      });
    },

    /**
     *  @actions:  导出评分模板
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/16 22:37
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleExportElxs() {
      this.$refs["scoring"].getScoreOptions((res) => {
        console.log("res", res);
        if (!res || !res.length) {
          this.$message.error("不存在评分项，无模板导出");
          return false;
        }
        // 一级表头
        let multiHeader = ["学号", "姓名"]
          .concat(res.map((item) => item.no))
          .concat(["", ""]);
        // 二级表头
        let twoHeader = ["", ""]
          .concat(
            res.map((item) => {
              if (
                item.indicator &&
                typeof item.indicator === "object" &&
                item.indicator.length
              ) {
                return item.indicator.map((part) => part.name).join(" ");
              } else {
                return "";
              }
            })
          )
          .concat(["", ""]);
        // 三级表头
        let tHeader = ["", ""]
          .concat(res.map((item) => item.name + "（" + item.value + "分）"))
          .concat(["", ""]);
        let fields = ["no", "username"]
          .concat(res.map((item) => item.no))
          .concat(["", ""]);
        // 合并单元格
        const merges = ["A1:A2:A3", "B1:B2:B3"];
        let formatJson = (fliterVal, tableData) => {
          tableData.map((v) => fliterVal.map((j) => v[j]));
          return tableData.map((v) => fliterVal.map((j) => v[j]));
        };
        let excelData = formatJson(fields, this.results.list);
        let desc = res[0].restriction_desc.split("\\n");
        desc.map((item, index) => {
          excelData[index][excelData[index].length - 1] = item;
        });

        export_json_to_excel({
          multiHeader2: multiHeader, //这里是第一行的表头
          multiHeader: twoHeader, // 第二行表头
          header: tHeader, //这里应该是算第二行的表头
          data: excelData, //数据
          merges: merges, //合并行
          filename: `${this.title}学生列表`,
          autoWidth: true,
          bookType: "xlsx",
        });
      });
    },

    /**
     *  @actions:  导出学生评分模板
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/23 18:25
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleExportStudentElxs() {
      this.$cloud
        .post("/mark/student", {
          roomId: this.roomId,
          self: "NO",
        })
        .then((res) => {
          // 一级表头
          let multiHeader = ["学号", "姓名"]
            .concat(res.map((item) => item.no))
            .concat(["", ""]);
          // 二级表头
          let twoHeader = ["", ""]
            .concat(
              res.map((item) => {
                if (
                  item.indicator &&
                  typeof item.indicator === "object" &&
                  item.indicator.length
                ) {
                  return item.indicator.map((part) => part.name).join(" ");
                } else {
                  return "";
                }
              })
            )
            .concat(["", ""]);
          // 三级表头
          let tHeader = ["", ""]
            .concat(res.map((item) => item.name + "（" + item.value + "分）"))
            .concat(["", ""]);
          let fields = ["no", "username"]
            .concat(res.map((item) => item.no))
            .concat(["", ""]);
          // 合并单元格
          const merges = ["A1:A2:A3", "B1:B2:B3"];
          let formatJson = (fliterVal, tableData) => {
            tableData.map((v) => fliterVal.map((j) => v[j]));
            return tableData.map((v) => fliterVal.map((j) => v[j]));
          };
          let excelData = formatJson(fields, this.results.list);

          export_json_to_excel({
            multiHeader2: multiHeader, //这里是第一行的表头
            multiHeader: twoHeader, // 第二行表头
            header: tHeader, //这里应该是算第二行的表头
            data: excelData, //数据
            merges: merges, //合并行
            filename: `${this.title}学生列表`,
            autoWidth: true,
            bookType: "xlsx",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *  @actions:  导入数据处理
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/17 11:36
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    httpRequest(e) {
      let file = e.file; // 文件信息
      // 错误情况判断
      if (!file) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      const fileReader = new FileReader(); // 读取文件
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result; // 获取结果
          // 获取所有表的信息
          const workbook = xlsx.read(data, {
            type: "binary", // 以字符编码的方式解析
            cellDates: true, // 将excel中日期类型数据，转化为标准日期格式，而不是默认的数字格式
          });
          // 获取第一张表的表名
          const exlname = workbook.SheetNames[0];
          // 转换成json数据
          const exl = xlsx.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
          // 打印 ws 就可以看到读取出的表格数据
          console.log("上传后的文件", exl);

          // 上传
          let api = () => {
            this.loading = true;
            // 提交上传数据
            this.$cloud
              .post("/record/import", {
                roomId: this.roomId,
                data: exl,
              })
              .then(() => {
                this.loading = false;
                this.onRefresh();
                this.$message.success("上传成功！");
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
          };

          let isExl = false;
          for (const exlKey in exl) {
            let values = Object.values(exl[exlKey]);
            values[0] += "";
            // 判断是不是学生数据
            if (values[0].indexOf("20") !== -1) {
              // console.log('values',values)
              let filter = values.filter((item) => item <= 1);
              if (filter.length) {
                isExl = true;
              }
            }
          }
          if (isExl) {
            this.$confirm("存在评分小于等于1的数据,是否继续导入?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              api();
            });
            return false;
          }
          api();
        } catch (e) {
          console.log("error", e);
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },

    /**
     *  @actions:  学生导入分数处理
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/23 18:38
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    httpRequestStudent(e) {
      let file = e.file; // 文件信息
      // 错误情况判断
      if (!file) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      const fileReader = new FileReader(); // 读取文件
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result; // 获取结果
          // 获取所有表的信息
          const workbook = xlsx.read(data, {
            type: "binary", // 以字符编码的方式解析
            cellDates: true, // 将excel中日期类型数据，转化为标准日期格式，而不是默认的数字格式
          });
          // 获取第一张表的表名
          const exlname = workbook.SheetNames[0];
          // 转换成json数据
          const exl = xlsx.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
          // 打印 ws 就可以看到读取出的表格数据
          console.log("上传后的文件:", exl);

          // 上传
          let api = () => {
            this.loading = true;
            // 提交上传数据
            this.$cloud
              .post("/record/student_import", {
                roomId: this.roomId,
                list: exl,
              })
              .then(() => {
                this.loading = false;
                this.onRefresh();
                this.$message.success("上传成功！");
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
          };

          let isExl = false;
          for (const exlKey in exl) {
            let values = Object.values(exl[exlKey]);
            values[0] += "";
            // 判断是不是学生数据
            if (values[0] && values[0].indexOf("20") !== -1) {
              // console.log('values',values)
              let filter = values.filter((item) => item <= 1);
              if (filter.length) {
                isExl = true;
              }
            }
          }
          if (isExl) {
            this.$confirm("存在评分小于等于1的数据,是否继续导入?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              api();
            });
            return false;
          }
          api();
        } catch (e) {
          console.log("导入出错", e);
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },

    /**
     *  @actions:  导出评分
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/26 21:11
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handlePrint() {
      this.handlePrintImage(this.roomId);
    },

    /**
     *  @actions:  查看教师评分
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/20 22:27
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCheckScore() {
      this.$refs["teacherScoreInfo"].open();
    },

    /**
     *  @actions:  一键打印互评表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/28 9:32
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handlePrintMutualAll() {
      this.$confirm(
        "生成互评图片并打印需要等待一定时间，生成图片过程中请勿操作系统，是否继续打印互评表?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let notify = this.$notify.warning({
          title: "系统消息",
          message:
            "正在努力生成打印数据表，请保持在当前页面，请勿关闭！具体导出时间视电脑配置而定。",
          duration: 0,
        });
        this.loading = true;
        this.$cloud
          .get("record/student_student", {
            roomId: this.roomId,
          })
          .then((res) => {
            if (res) {
              this.mutualColumns = {
                no: "学号",
                username: "姓名",
                ...res.columns,
              };
              this.tableData = res.data;
              this.$nextTick(() => {
                setTimeout(() => {
                  this.handlePrintMutualDetails(
                      this.$refs["printMutualTable"],
                      notify
                  );
                },1000)
              });
            } else {
              this.loading = false;
              this.$message.error(res);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },
    /**
     *  @actions:  设置分组
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/26 21:41
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSetGroups() {
      this.$nextTick(() => {
        this.$refs["groups"].open();
      });
    },

    // 查看历史成绩
    handleHistoryGrades() {
      this.$nextTick(() => {
        this.$refs["historyGrades"].open();
      });
    },

    // 导出班级报告
    async handleExportReport() {
      await this.$cloud
        .get("/record/tableexport", { roomId: this.roomId })
        .then((res) => {
          if (Object.keys(res).length) {
            // 指标 columns
            let target = [];
            Object.keys(res).map((key, index) => {
              let item = res[key];
              if (index === 0) {
                for (const itemKey in item) {
                  if (!isNaN(Number(itemKey))) {
                    target.push({ no: item[itemKey].title });
                  }
                }
              }
              // console.log("item::", item);
            });

            // 班级信息
            let obj = {
              name: this.title,
              year: this.classinfo.semester_year,
              semester: this.classinfo.semester,
            };

            // 打印数据
            let result = [];
            for (const resKey in res) {
              result.push(res[resKey]);
            }

            // 打印数据
            let excelData = [];
            result.map((item) => {
              let arrays = [item.no, item.name];
              Object.keys(item).map((key) => {
                if (!isNaN(Number(key))) {
                  arrays.push(item[key].total);
                }
              });
              arrays.push(item.total);
              excelData.push(arrays);
            });
            excelData.push(["教师签字"]);

            let columns = [
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
            ];
            // 合并单元格
            let arr = [];
            let str = "";
            let rowsIndex = excelData.length + 2;
            let signname = "";
            let length = target.length + 3;
            for (let i = 0; i < length; i++) {
              arr.push("");
              if (i === length - 1) {
                str += columns[i] + "1";
                signname += columns[i] + rowsIndex;
              } else {
                str += columns[i] + "1:";
                if (i !== 0) {
                  signname += columns[i] + rowsIndex + ":";
                }
              }
            }
            // 合并单元格
            const merges = [str, signname];

            // 一级表头
            let title = `${obj.name}班综合素质评价汇总表  ${obj.year}学年 第${
              obj.semester === 2 ? "二" : "一"
            }学期`;
            let multiHeader = [title].concat(arr);
            // 二级表头
            let tHeader = ["学号", "姓名"]
              .concat(target.map((item) => item.no))
              .concat(["总分"]);

            export_json_to_excel({
              multiHeader: multiHeader, //这里是第一行的表头
              header: tHeader, //这里应该是算第二行的表头
              data: excelData, //数据
              merges: merges, //合并行
              filename: title,
              autoWidth: false,
              bookType: "xlsx",
            });
          } else {
            this.$message.error("暂无汇总表数据！");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    // 申诉修改
    handleAppealUpdate() {
      this.$message.error("申诉修改正在开发中...");
    },

    // 获取学生列表
    async handleGetStudents() {
      await this.$cloud
        .get("/login/info")
        .then(async (res) => {
          this.userinfo = res;
          if (res.role === "admin" || res.role === "teacher") {
            this.roomId = this.$route.query["id"];
            // 判断是否为班主任
            this.isMaster = parseInt(this.$route.query["isMaster"]) === 1;

            if (!this.isMaster) {
              this.columns = this.columns.filter(
                (item) => !["rate"].includes(item.field)
              );
            }

            delete this.params["groupId"];
            this.params["roomId"] = this.roomId;
            let subjectRoom = [];
            if (
              res.subjectRoom &&
              typeof res.subjectRoom == "object" &&
              res.subjectRoom.length
            ) {
              res.subjectRoom.forEach((item) => {
                if (Number(item.room.id) === Number(this.roomId)) {
                  this.subjectsName = item.subject
                    .map((part) => part.name + "课")
                    .join("，");
                }
                subjectRoom.push(item.room);
              });
            }

            let arrays = [];
            if (res.masterRoom && subjectRoom.length) {
              arrays = [res.masterRoom].concat(subjectRoom);
            } else if (res.masterRoom && !subjectRoom.length) {
              arrays = [res.masterRoom];
            } else if (!res.masterRoom && subjectRoom.length) {
              arrays = subjectRoom;
            }
            let find = arrays.find(
              (item) => parseInt(item.id) === parseInt(this.roomId)
            );

            let name =
              find.name.length > 1
                ? String(find.name)
                : "0" + String(find.name);
            this.title = find.level + name + "班";
            this.classinfo = find;

            await this.getSocreWoring(); // 获取评分预警
            await this.getScoreList("teacher"); // 教师获取列表中的评分分数
            await this.getScoreTableHeader(); // 教师获取列表中的评分分数表头
            await this.handleOnSearch(); // 查询学生列表
          } else if (res.no) {
            //判断学生是否需要强制修改密码
            if (res.editPassword === "NO") {
              this.$refs["modify"].open("student");
            }

            this.classinfo = res.room;
            this.roomId = res.room.id;
            this.title =
              res.room.level +
              (res.room.name.length > 1 ? res.room.name : "0" + res.room.name) +
              "班" +
              (res.group?.name || "");
            this.columns = this.columns.filter(
              (item) => !["group", "rate"].includes(item.field)
            );
            this.params["roomId"] = this.roomId;
            if (res.groupId) {
              this.params["groupId"] = res.groupId;
            }

            await this.getStudentScoreTableHeader(); // 学生获取列表中的评分分数表头
            await this.getScoreList("student"); // 学生获取列表中的评分分数
            await this.getScoreList("self", "push");
            await this.handleOnSearch();
          }

          await this.getScoreTimeRange(); // 判断是否在评分周期内
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    // 获取评分预警
    async getSocreWoring() {
      this.loading = true;
      await this.$cloud
        .get("record/warning", { roomId: this.roomId })
        .then((res) => {
          this.loading = false;
          if (res && res.length && res.length > 0) {
            let message = res.map((item) => item.name).join("、");
            this.$notify({
              title: "系统提醒",
              message: `${message}评分异常，请重新导入！`,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // 查询学生
    async handleOnSearch() {
      this.$nextTick(() => {
        this.$refs["main"].onFind();
      });
    },

    // 获取列表中的评分分数
    async getScoreList(from, type = null) {
      this.loading = true;
      await this.$cloud
        .post("record/student_list_by_teacher", {
          roomId: this.roomId,
          from: from,
        })
        .then(async (res) => {
          this.loading = false;
          if (type === "push") {
            await this.onPushSelfScore(res);
          } else {
            this.scoreList = res;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 将自评数据处理后放入列表之中
    onPushSelfScore(data) {
      this.$cloud
        .get("/evaluation/detail_by_room_v2", {
          roomId: this.roomId,
        })
        .then((res) => {
          let target = res.dimensions.map((item) => {
            // 互评编号
            let hp = item.marks.find((part) => part["from_subject_id"] === 20);
            // 自评编号
            let zp = item.marks.find((part) => part["from_subject_id"] === 21);
            return {
              e: hp.no,
              s: zp.no,
            };
          });

          let selfNo = Object.keys(data);
          let values = data[selfNo[0]].map((item) => {
            let find = target.find((part) => item.no === part.s);
            item["no"] = find["e"];
            return item;
          });
          this.scoreList = {
            ...this.scoreList,
            [selfNo[0]]: values,
          };
          console.log("指标:", target, data);
        });
    },

    //教师获取列表中的评分分数表头
    async getScoreTableHeader() {
      this.loading = true;
      await this.$cloud
        .post("/mark/index", {
          roomId: this.roomId,
          from: "teacher",
        })
        .then((res) => {
          this.loading = false;
          let c = [];
          this.columns.map((item) => {
            if (!item.score) {
              c.push(item);
            }
          });
          res.map((item, index) => {
            let obj = {
              field: item.no,
              label: item.indicator.map((i) => i.name).join("、"),
              align: "center",
              width: item.indicator.length * 100 + "px",
              score: true,
            };
            c.splice(2 + index, 0, obj);
          });
          this.columns = c;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    //学生获取列表中的评分分数表头
    async getStudentScoreTableHeader() {
      this.loading = true;
      await this.$cloud
        .post("/mark/student", {
          roomId: this.roomId,
        })
        .then((res) => {
          this.loading = false;
          res.map((item, index) => {
            let obj = {
              field: item.no,
              label: item.dimension_name,
              align: "center",
              width: "100px",
            };
            this.columns.splice(2 + index, 0, obj);
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // 设置学生是否可以登录评分
    async handleChangeIscore(ret, row) {
      let params = {
        studentIds: [row.id],
        rate: ret,
      };
      await this.handleSetSate(params);
    },

    // 批量设置权限
    handleChangeAllIscore(ret) {
      if (this.isMaster) {
        let params = {
          studentIds: this.results.list.map((item) => item.id),
          rate: ret,
        };
        this.handleSetSate(params);
      } else {
        this.$message.error("该功能只对班主任开放！");
      }
    },

    // 设置评分权限
    async handleSetSate({ groupId, studentIds, rate }) {
      this.loading = true;
      await this.$cloud
        .post("/group/studentrate", {
          groupId,
          studentIds,
          rate,
        })
        .then(() => {
          this.loading = false;
          this.handleOnSearch();
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
          this.handleOnSearch();
        });
    },

    // 教师打分
    handleScore(row) {
      this.$refs["scoring"].open(row, this.userinfo);
    },

    // 学生打分
    handleStedentScore(row, index) {
      this.$refs["stedentScore"].open(row, this.userinfo, index, "score");
    },

    // 导出个人成绩
    handleExportResult() {
      // this.$refs["statistics"].open(row, this.classinfo);
    },

    // 申诉
    handleAppeal(row) {
      this.$refs["appeal"].open(
        {
          ...row,
          isMaster: this.isMaster,
        },
        this.userinfo
      );
    },

    // 查看成绩
    handleCheckGrades(row, index) {
      // 教师评分
      if (this.userinfo.no) {
        this.$refs["stedentScore"].open(row, this.userinfo, index, "check");
      } else {
        this.$refs["scoring"].open(row, this.userinfo, "check");
      }
    },

    // 查分
    handleCheckFraction(row, index) {
      this.$refs["checkScores"].open({ row, index });
    },

    /**
     *  @actions:  修改评分
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/18 20:32
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleEditGrades(row, index) {
      this.$refs["stedentScore"].open(row, this.userinfo, index, "edit");
    },

    //刷新
    async onRefresh() {
      if (this.userinfo.no) {
        await this.getScoreList("student"); // 学生重新获取列表
        await this.getScoreList("self", "push");
      } else {
        await this.getScoreList("teacher"); // 教师重新获取
      }
      this.$refs["main"].onQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
.dummyTextarea {
  border: solid 1px #c0c4cc;
  width: 100%;
  color: #606266;
  font-size: 14px;
  padding: 5px 15px;
  line-height: 18px;
  border-radius: 3px;

  &:focus {
    outline-color: #409eff;
  }

  &::placeholder {
    color: #c0c4cc;
  }
}

.print-table-height {
  border: 1px solid #000;
  border-right-width: 2px;
  border-bottom-width: 2px;

  ::v-deep .el-table__cell {
    padding: 0px !important;

    .cell {
      line-height: 28px;
      font-size: 14px !important;
      transform: translateY(-25%);
    }
  }

  //::v-deep .el-table__cell.is-leaf{
  //  border-bottom: 2px solid #000;
  //}
  //::v-deep .el-table__cell:last-child{
  //  border-right: 0 !important;
  //}
  //::v-deep .has-gutter tr .el-table__cell:last-child{
  //  border-right: 0 !important;
  //  color: #ff4d51;
  //}
}
</style>
