<!--
 * ======================================
 * 说明： 评分申诉
 * 作者： Silence
 * 文件： appeal.vue
 * 日期： 2023/5/9 23:19
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    :title="`【${title}】评分申诉`"
    width="600px"
    height="340px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <el-form
      v-loading="loading"
      :model="editForm"
      :rules="rule"
      ref="editForm"
      label-width="80px"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="评价内容" prop="mark_id">
            <el-select
              v-model="editForm.mark_id"
              @change="handleSelectOption"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in options"
                :value="item.id"
                :label="item.no+item.name"
                :key="index"
              >
                <span style="float: left">{{ item.no }}{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.indicator
                    ? item.indicator.map((part) => part.name).join(" ")
                    : ""
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="申诉原因" prop="reason">
            <el-input
              resize="none"
              type="textarea"
              rows="5"
              v-model="editForm.reason"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {
    userinfo: Object,
    roomId: [String, Number],
  },
  data() {
    return {
      editForm: {},
      title: "",
      visible: false,
      loading: false,
      options: [],
      scored: {}, // 选择的申诉内容
      master: "",
      subjects: [],
      loginInfo: {},
      rule: {
        mark_id: [
          {
            required: true,
            trigger: "blur",
            message: "请选择评价目标！",
          },
        ],
        reason: [
          {
            required: true,
            trigger: "blur",
            message: "请选择申诉原因！",
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开窗口
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 23:19
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async open(data, loginInfo) {
      this.editForm = {};
      this.loginInfo = loginInfo;
      this.editForm["studentId"] = data.id;
      this.master = data.isMaster;
      this.title = data.username;
      this.visible = true;
      await this.getOptions();
    },

    /**
     *  @actions:  获取申诉目标选项
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 23:43
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getOptions() {
      // 请求参数
      let params = {
        // type: this.loginInfo.no ? 2 : 1, // 1:学生 2:教师
        UID: this.loginInfo.id,
        roomId:this.roomId,
      };

      this.loading = true;
      this.$cloud
        .post("/mark/index", params)
        .then((res) => {
          this.loading = false;
          this.options = res;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  选择的评价内容
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/19 13:39
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSelectOption(id) {
      this.scored = this.options.find( ( item ) => item.id === id );
    },

    /**
     *  @actions:  确定申诉
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 23:23
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      let params = {
        roomId: this.roomId,
        studentId: this.editForm.studentId,
        no: this.scored.no,
        subjectId: this.scored.subject_id,
        to: this.master ? "admin" : "master",
        reason: this.editForm.reason,
        evaluationId: this.scored.evaluation_id,
        markId:this.scored.id,
      };
      this.$refs["editForm"].validate((vaild) => {
        if (vaild) {
          this.loading = true;
          this.$cloud
            .post("/statement/apply", params)
            .then(() => {
              this.$message.success("申诉成功！");
              this.visible = false;
              this.loading = false;
            })
            .catch((err) => {
              this.$message.error(err)
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
