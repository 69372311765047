<!--
 * ======================================
 * 说明： 学生自评或互评弹窗
 * 作者： Silence
 * 文件： stedentScore.vue
 * 日期： 2023/5/25 22:04
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    :title="`${studentInfo.username}同学综合评分`"
    width="600px"
    height="510px"
    v-model="visible"
    :is-footer="true"
  >
    <div
      style="overflow: hidden; overflow-y: auto; height: 100%"
      v-loading="loading"
    >
      <div class="h-full" style="overflow: hidden; overflow-y: auto">
        <el-form ref="form" class="border border-solid">
          <el-row :gutter="20" class="border-b border-solid">
            <el-col :span="12" class="p-2 text-center border-r border-solid">
              {{ title }}
            </el-col>
            <el-col :span="12" class="p-2 text-center">
              <span
                v-if="logininfo.no === studentInfo['no']"
                class="text-red-600"
                >学生自评分</span
              >
              <span v-else>学生互评分</span>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            v-for="(item, index) in datas"
            :key="index"
            :class="index < datas.length - 1 ? 'border-b border-solid' : ''"
          >
            <el-col
              :span="12"
              class="
                p-2
                h-12
                text-center
                border-r border-solid
                flex
                justify-center
                items-center
              "
            >
              <!-- 该方法进行特殊处理  -->
              <!--              {{ showName(item) }}-->
              <!--   统一显示评价维度 -->
              {{ item.dimension_name }} ( {{ item.value }} )
            </el-col>
            <el-col
              :span="12"
              class="p-2 h-12 flex justify-center items-center"
            >
              <el-input-number
                v-model="item['score']"
                class="stedent-scoring-input"
                :disabled="mode === 'check'"
                :style="!item.isVerify ? 'border-bottom:solid 1px red' : ''"
                :min="0"
                :max="Number(item.value)"
                :controls="false"
                @blur="handleVerify(item.score, item, index)"
              ></el-input-number>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <template #footer>
      <el-button
        v-if="index < list.length - 1 && mode === 'score'"
        type="success"
        size="mini"
        @click="handleSaveContinue"
        >保存并继续</el-button
      >
      <el-button size="mini" @click="handleCancel">取消</el-button>
      <el-button
        v-if="mode !== 'check'"
        type="primary"
        size="mini"
        @click="handleConfirm(null)"
        >确定</el-button
      >
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {
    roomId: [String, Number],
    list: {
      type: Array,
      default: () => [],
    },
    classInfo: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$emit("refresh");
      }
    },
  },
  data() {
    return {
      studentInfo: {},
      logininfo: {},
      datas: [],
      index: null, //当前学生所处数组下标
      visible: false,
      loading: false,
      title: "评价维度",

      mode: null,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开窗口
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/25 22:10
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    open(info, loginInfo, index, mode) {
      this.index = index;
      this.visible = true;
      this.studentInfo = info;
      this.logininfo = loginInfo;
      this.mode = mode;
      let isSelf = [];
      if (this.logininfo.no !== info["no"]) {
        isSelf = "NO";
      } else {
        isSelf = "YES";
      }

      this.getScoreOptions(isSelf);
    },

    /**
     *  @actions:  匹配名字显示
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/31 22:02
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    showName(data) {
      let name = null;
      const level = Number(this.classInfo["level"]);
      switch (Number(this.classInfo["semester_year"])) {
        case 2022:
          if (level > 7) {
            this.title = "评价指标";
            if (data.indicator && data.indicator.length) {
              name =
                data.indicator.map((item) => item.name).join("，") +
                "（" +
                data.value +
                "分）";
            } else {
              name = "";
            }
          } else {
            this.title = "评价维度";
            name = data.dimension_name + "（" + data.value + "分）";
          }
          break;
        case 2023:
          if (level < 9) {
            this.title = "评价维度";
            name = data.dimension_name + "（" + data.value + +"分）";
          } else {
            this.title = "评价指标";
            if (data.indicator && data.indicator.length) {
              name =
                data.indicator.map((item) => item.name).join("，") +
                "（" +
                data.value +
                "分）";
            } else {
              name = "";
            }
          }
          break;
        default: {
          this.title = "评价维度";
          //2024 年 之后自评与互评 采用统一标准  显示维度
          name = data.dimension_name + "（" + data.value + +"分）";
        }
      }
      console.log(data, this.classInfo);
      return name;
    },

    // 输入验证
    handleVerify(value, item, index) {
      if (!value || value === 0 || value === "0") {
        item["isVerify"] = false;
        this.$message.error(`请输入正确的评分！`);
        return;
      }

      // 全局变量控制是否开启评分限制验证
      // 评分为自评时，不做评分限制验证
      if (
        !window.STUDENT_SCORE_LIMIT ||
        this.logininfo.no === this.studentInfo["no"]
      ) {
        item["isVerify"] = true;
        return false;
      }

      this.$cloud
        .get("/mark/verify", {
          roomId: this.roomId,
          mark_id: item.id,
          value: value || 0,
        })
        .then((res) => {
          item["isVerify"] = false;
          this.datas.splice(index, 1, item);
          if (res && typeof res === "object") {
            this.$message.error(
              `占比 ${res.min}-${res.max}占比${res.ratio} 打分已饱和`
            );
          } else if (res) {
            item["isVerify"] = true;
            this.datas.splice(index, 1, item);
            // this.$set(this.datas[index],'isVerify',true)
          }
        })
        .catch((err) => {
          item["isVerify"] = false;
          this.datas.splice(index, 1, item);
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  获取评分项
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/25 22:25
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getScoreOptions(isSelf) {
      this.loading = true;
      this.$cloud
        .post("/mark/student", {
          roomId: this.roomId,
          self: isSelf,
        })
        .then((res) => {
          this.loading = false;
          this.datas = res;
          this.datas.map((item) => {
            item["score"] = 0;
            item["isVerify"] = true;
          });

          if (this.mode !== "score") {
            this.getGrades(isSelf);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  查看获取评分
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/3 23:04
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getGrades(isSelf) {
      this.loading = true;
      this.$cloud
        .post("/record/records", {
          roomId: this.roomId,
          student_id: this.studentInfo.id,
          from: isSelf === "YES" ? "self" : "student",
        })
        .then((res) => {
          this.loading = false;
          if (res && typeof res === "object" && res.length) {
            this.datas.map((item) => {
              let find = res.find((part) => part.mark_id === item.id);
              if (find) {
                item["editId"] = find.id;
                item["score"] = find["score"];
              }
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  保存并继续
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/25 23:39
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSaveContinue() {
      this.handleConfirm(() => {
        for (let i = this.index + 1; i < this.list.length; i++) {
          let item = this.list[i];
          if (item["hasEval"] === "NO") {
            // this.$emit("refresh");
            this.open(item, this.logininfo, i);
            break;
          }
        }
      });
    },

    /**
     *  @actions:  确认评分
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/25 22:06
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm(callback) {
      // 验证是否打有效分值
      for (const index in this.datas) {
        let item = this.datas[index];
        if (!item.score || item.score === 0 || !item.isVerify) {
          this.$message.error(`请输入正确的评分！`);
          return false;
        }
      }

      this.$confirm("确定保存当前评分吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 将参数处理成接口所需的式样
        let from = "student";
        if (this.studentInfo.id === this.logininfo.id) {
          from = "self";
        }

        let params = [];
        this.datas.map((item) => {
          params.push({
            room_id: this.roomId,
            student_id: this.studentInfo.id,
            mark_id: item.id,
            no: item.no,
            score: item.score,
            from_subject_id: item.subject_id,
            from: from,
            from_id: this.logininfo.id,
          });
        });

        let url = "/record/create",
          massage = "评分成功";
        if (this.mode === "edit") {
          (url = "/record/student_edit"), (massage = "修改评分成功");
          params = {
            list: this.datas.map((item) => {
              return {
                room_id: this.roomId,
                student_id: this.studentInfo.id,
                mark_id: item.id,
                no: item.no,
                score: item.score,
                from_subject_id: item.subject_id,
                from: from,
                from_id: this.logininfo.id,
                id: item.editId,
              };
            }),
          };
        }

        this.loading = true;
        this.$cloud
          .post(url, params)
          .then(() => {
            this.loading = false;
            this.$message.success(massage);
            if (callback) {
              callback();
            } else {
              this.handleCancel();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },

    /**
     *  @actions:  取消关闭并刷新
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/26 0:31
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.stedent-scoring-input {
  width: 100%;
  height: 100%;
  ::v-deep .el-input__inner {
    border: 0;
    border-radius: 0;
    text-align: center;
    //background-color: red;
    width: 100%;
    height: 100%;
    //border-bottom: 1px solid #dcdfe6;
  }
  ::v-deep .el-input {
    height: 100%;
  }
}
</style>
