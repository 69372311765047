<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-04-29 23:30:08
 * @LastEditors:
 * @LastEditTime: 2023-04-29 23:30:08
-->
<template>
  <Dialog
    :title="`${studentInfo.username}同学综合评分`"
    width="1200px"
    height="80%"
    :is-confirm="mode !== 'check'"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <div
      style="overflow: hidden; overflow-y: auto; height: 100%"
      v-loading="loading"
    >
      <div
        class="h-full border-solid border"
        style="overflow: hidden; overflow-y: auto"
      >
        <el-form :model="editform" ref="editForm" :show-message="false">
          <div
            v-for="(item, index) in datas"
            :key="index"
            class="p-2 border-solid border-b"
          >
            <h4 style="font-size: 26px" class="text-gray-800 pb-2">
              {{ item.no }}{{ item.name }}
            </h4>
            <el-row
              v-for="(part, partIndex) in item.indicator"
              :key="partIndex"
            >
              <el-col :span="24">
                <h4 class="text-xl my-2">{{ part.name }}</h4>
                <p class="text-sm text-gray-400 mb-1">{{ part.desc }}</p>
              </el-col>
            </el-row>
            <div class="inline-flex justify-items-start items-center">
              <span class="w-30">评分（{{ item.value }} 分）：</span>
              <el-form-item
                :prop="item['field']"
                :rules="[{ required: true, trigger: 'blur' }]"
              >
                <el-input-number
                  :disabled="mode === 'check'"
                  class="scoring-input"
                  :style="!item.isVerify ? 'border-bottom:solid 1px red' : ''"
                  :controls="false"
                  :min="0"
                  :max="Number(item.value)"
                  @blur="handleVerify(item.score, item, index)"
                  v-model="item.score"
                ></el-input-number>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "scoring",
  components: { Dialog },
  props: {
    roomId: [String, Number],
  },
  data() {
    return {
      visible: false,
      loading: false,
      studentInfo: {}, // 选择的的学生信息
      logininfo: {}, // 登录的学生信息
      editform: {},
      datas: [],
      mode: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 打开
    open(info, loginInfo, mode) {
      this.visible = true;
      this.studentInfo = info;
      this.logininfo = loginInfo;
      this.getScoreOptions();
      this.mode = mode;
    },

    getScoreOptions(callback = () => {}) {
      let url = "/mark/index";
      // 请求参数
      let params = {
        UID: this.logininfo.id,
        roomId: this.roomId,
      };

      this.loading = true;
      this.$cloud
        .post(url, params)
        .then((res) => {
          this.loading = false;
          this.datas = res;
          this.datas.map((item) => {
            item["score"] = 0;
            item["isVerify"] = true;
          });

          if (this.mode === "check") {
            this.getGrades();
          }

          callback(this.datas);
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // 获取评分成绩
    getGrades() {
      this.loading = true;
      this.$cloud
        .post("/record/records", {
          roomId: this.roomId,
          student_id: this.studentInfo.id,
          from: "teacher",
        })
        .then((res) => {
          this.loading = false;
          if (res && typeof res === "object" && res.length) {
            this.datas.map((item) => {
              let find = res.find((part) => part.mark_id === item.id);
              if (find) {
                item["score"] = find["score"];
              }
            });
          }
          console.log("res::", this.datas, res);
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // 输入验证
    handleVerify(value, item, index) {
      if (!value || value === 0 || value === "0") {
        item["isVerify"] = false;
        this.$message.error(`请输入正确的评分！`);
        return;
      }

      // 全局变量控制是否开启评分限制验证
      if (!window.STUDENT_SCORE_LIMIT) {
        item["isVerify"] = true;
        return false;
      }

      this.$cloud
        .get("/mark/verify", {
          roomId: this.roomId,
          mark_id: item.id,
          value: value || 0,
        })
        .then((res) => {
          item["isVerify"] = false;
          this.datas.splice(index, 1, item);
          if (res && typeof res === "object") {
            this.$message.error(
              `占比 ${res.min}-${res.max}占比${res.ratio} 打分已饱和`
            );
          } else if (res) {
            item["isVerify"] = true;
            this.datas.splice(index, 1, item);
            // this.$set(this.datas[index],'isVerify',true)
          }
        })
        .catch((err) => {
          item["isVerify"] = false;
          this.datas.splice(index, 1, item);
          this.$message.error(err);
        });
    },

    // 保存
    handleConfirm() {
      this.$refs["editForm"].validate((voild) => {
        if (voild) {
          // 验证是否打有效分值
          for (const index in this.datas) {
            let item = this.datas[index];
            if (!item.score || item.score === 0 || !item.isVerify) {
              this.$message.error(`请输入正确的评分！`);
              return false;
            }
          }

          this.$confirm("确定保存当前评分吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            // 将参数处理成接口所需的式样
            let from = "teacher";
            if (this.logininfo.no) {
              from = "student";
              if (this.studentInfo.id === this.logininfo.id) {
                from = "self";
              }
            }

            let params = [];
            this.datas.map((item) => {
              params.push({
                room_id: this.roomId,
                student_id: this.studentInfo.id,
                mark_id: item.id,
                no: item.no,
                score: item.score,
                from_subject_id: item.subject_id,
                from: from,
                from_id: this.logininfo.id,
              });
            });

            this.loading = true;
            this.$cloud
              .post("/record/create", params)
              .then(() => {
                this.loading = false;
                this.visible = false;
                this.$message.success("评分成功");
                this.$emit("refresh");
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.scoring-input {
  width: 90px;
  ::v-deep .el-input__inner {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #dcdfe6;
    text-align: center;
  }
}
</style>
