<!--
 * ======================================
 * 说明： 历史分数
 * 作者： Silence
 * 文件： historyGrades.vue
 * 日期： 2023/7/25 23:07
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    :title="`【${className}】历史成绩`"
    :fullscreen="true"
    v-model="visible"
  >
    <ClassHistory :classId="classId"></ClassHistory>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog/index.vue";
import ClassHistory from "@/pages/history/class.vue";
export default {
  name: "historyGrades",
  components: {Dialog,ClassHistory},
  props: {
    classId: Array,
    className: String,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    open(){
      this.visible = true
    },
  },
};
</script>
<style lang="scss" scoped></style>
