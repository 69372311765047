<!--
 * ======================================
 * 说明： 教师评分结果
 * 作者： Silence
 * 文件： teacherScoreInfo.vue
 * 日期： 2023/6/21 0:11
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    title="评分情况"
    width="1300px"
    height="620px"
    :is-confirm="false"
    v-model="visible"
  >
    <div class="dialog-main">
      <div>
        <h4 class="dialog-main_title">教师评分情况：</h4>
        <Table
          :height="420"
          :is-search="false"
          :is-selection="false"
          :is-page="false"
          v-loading="loading"
          :columns="columns"
          :rows.sync="tableData"
        >
          <template #table-item-subject="{ row }">
            {{ row["subject"].name }}
          </template>
          <template #table-item-teacher="{ row }">
            {{ row["teacher"].username }}
          </template>
          <template #table-item-status="{ row }">
            <el-tag type="success" v-if="row['yes'].length === total"
              >已评分</el-tag
            >
            <el-tag type="primary" v-if="row['yes'].length && row['no'].length"
              >评分中</el-tag
            >
            <el-tag type="danger" v-if="row['no'].length === total"
              >未评分</el-tag
            >
          </template>
          <template #table-item-yes="{ row }">
            {{ row["yes"].length }}
          </template>
          <template #table-item-no="{ row }">
            {{ row["no"].length }}
          </template>
        </Table>
      </div>
      <div>
        <h4 class="dialog-main_title">
          学生评分情况：<span style="color: red; font-size: 10px"
            >互评人数为当前学生评价其他学生人数；未互评人数为全班人数减去当前学生和已评价学生。</span
          >
        </h4>
        <Table
          :height="420"
          :is-selection="false"
          :is-page="false"
          v-loading="loading"
          :columns="studentColumns"
          :rows.sync="studentData"
        >
          <template #table-item-selfRecord="{ row }">
            <el-tag type="success" v-if="row['selfRecord']">已评</el-tag>
            <el-tag type="danger" v-else>未评</el-tag>
          </template>

          <template #table-item-record="{ row }">
            <el-tag
              type="success"
              v-if="row['myStudentRecord'].length === total - 1"
              >已评分</el-tag
            >
            <el-tag
              type="primary"
              v-if="
                row['myStudentRecord'].length &&
                row['myStudentRecord'].length < total - 1
              "
              >评分中</el-tag
            >
            <el-tag type="danger" v-if="!row['myStudentRecord'].length"
              >未评分</el-tag
            >
          </template>

          <template #table-item-yes="{ row }">
            {{ row["myStudentRecord"].length }}
          </template>
          <template #table-item-no="{ row }">
            {{ total - row["myStudentRecord"].length - 1 }}
          </template>
        </Table>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
import Table from "@/pages/components/table";
export default {
  name: "",
  components: { Dialog, Table },
  props: {
    roomId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      tableData: [],
      studentData: [],
      total: 0, // 学生总数
      columns: [
        {
          field: "subject",
          label: "科目",
          align: "left",
          width: "auto,",
        },
        {
          field: "teacher",
          label: "教师",
          align: "left",
          width: "auto,",
        },
        {
          field: "status",
          label: "评分状态",
          align: "left",
          width: "auto,",
        },
        {
          field: "yes",
          label: "已评人数",
          align: "left",
          width: "auto,",
        },
        {
          field: "no",
          label: "未评人数",
          align: "left",
          width: "auto,",
        },
      ],
      studentColumns: [
        {
          field: "username",
          label: "学生姓名",
          align: "left",
          width: "auto,",
        },
        {
          field: "selfRecord",
          label: "自评",
          align: "left",
          width: "auto,",
        },
        {
          field: "record",
          label: "互评",
          align: "left",
          width: "auto,",
        },
        {
          field: "yes",
          label: "互评人数",
          align: "left",
          width: "auto,",
        },
        {
          field: "no",
          label: "未互评人数",
          align: "left",
          width: "auto,",
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开窗口
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/21 0:17
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    open() {
      this.visible = true;
      this.getResult();
    },

    /**
     *  @actions:  获取评分详情
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/21 0:21
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getResult() {
      this.loading = true;
      this.$cloud
        .get("record/room_teacher_record_list", {
          roomId: this.roomId,
        })
        .then((res) => {
          this.loading = false;
          if (res && res.length) {
            this.studentData = res;
            console.log("studentData:", res);
            this.total = res.length;
            this.tableData = []; // 科目数组
            res.map((item) => {
              if (item.Record && item.Record.length) {
                item.Record.map((i) => {
                  let find = this.tableData.find((a) => {
                    return a.subject.id === i.subject.id;
                  });
                  if (!find) {
                    this.tableData.push(i);
                  }
                });
              }
              if (item.unRecord && item.unRecord.length) {
                item.unRecord.map((i) => {
                  let find = this.tableData.find((a) => {
                    return a.subject.id === i.subject.id;
                  });
                  if (!find) {
                    this.tableData.push(i);
                  }
                });
              }
            });
            this.tableData.map((item) => {
              item["yes"] = [];
              item["no"] = [];
              res.map((i) => {
                // 已评
                let yes = i.Record.find(
                  (a) => a.subject.id === item.subject.id
                );
                if (yes) {
                  item["yes"].push({
                    studentId: i.id,
                    studentNo: i.no,
                  });
                }
                // 未评
                let no = i.unRecord.find(
                  (a) => a.subject.id === item.subject.id
                );
                if (no) {
                  item["no"].push({
                    studentId: i.id,
                    studentNo: i.no,
                  });
                }
              });
            });
          }
          console.log("res", this.tableData);
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-main {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  item-align: center;
  > div {
    width: calc(50% - 20px);
  }
  .dialog-main_title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }
}
</style>
