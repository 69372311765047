/**
 * ======================================
 * 说明： 批量打印图片
 * 作者： Silence
 * 文件： mixin.js
 * 日期： 2023/5/26 15:43
 * ======================================
 */
import html2canvas from "html2canvas";
import printJS from "print-js";
import dayjs from "dayjs";
export default {
  data() {
    return {
      list: [],
      imgs: [],
      images: {
        width: 1000,
        height: 1414,
      },

      domToImgs: [],
    };
  },
  mounted() {},
  methods: {
    // 获取导出数据
    async getExportDatas(roomId) {
      await this.$cloud
        .get("/record/tableexport", { roomId })
        .then((res) => {
          if(res && Object.keys(res).length){
            this.list = res;
            this.drawImg().then(() => {
              printJS({
                // 要打印的内容 放在了data里
                printable: this.imgs,
                // 打印类型 目前为图片样式 可以根据上面的网址进行修改
                type: "image",
              });
              this.imgs = [];
            });
          }else{
            this.$message.error('暂无反馈表数据！')
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 绘图
    drawImg() {
      let that = this;
      return new Promise(function (resolve) {
        let canvas = document.getElementById("mycanvas");
        let context = canvas.getContext("2d");

        let arrays = [];
        for (const i in that.list) {
          arrays.push(that.list[i]);
        }

        for (let i = 0; i < arrays.length; i++) {
          let item = arrays[i];
          let img = new Image();
          img.src = require("@/assets/export.png");
          // 加载图片
          img.onload = function () {
            if (img.complete) {
              // 根据图像重新设定了canvas的长宽
              canvas.setAttribute("width", that.images.width);
              canvas.setAttribute("height", that.images.height);
              // 绘制图片
              context.drawImage(
                img,
                0,
                0,
                that.images.width,
                that.images.height
              );

              context.font = "30px Arial bolder";
              context.fillStyle = "block";

              // 绘制班级
              context.fillText(
                that.title.slice(0, that.title.length - 1),
                100,
                275
              );

              // 绘制名字
              if (item["name"].length < 4) {
                let num = ((item["name"].length - 2) / 2) * 30;
                context.fillText(item["name"], 205 - num, 275);
              } else {
                context.font = "23px Arial bolder";
                context.fillText(item["name"], 188, 275);
              }

              // 绘制日期
              let nowDate = dayjs().format("YYYY-MM-DD");
              if (nowDate) {
                context.font = "23px Arial bolder";
                context.fillStyle = "block";
                let date = nowDate.split("-");
                context.fillText(date[0], 665, 660);
                context.fillText(date[1], 755, 660);
                context.fillText(date[2], 805, 660);
              }

              // 绘制分数
              context.font = "32px Arial bolder";
              context.fillStyle = "red";
              // 当小数位大于三位时，保留两位小数
              let total =
                item["total"].toString().length > 5
                  ? item["total"].toFixed(2)
                  : item["total"];
              //设置位置
              let xt, xb;
              switch (total.toString().length) {
                case 2:
                  context.font = "46px Arial bolder";
                  (xt = 625), (xb = 588);
                  break;
                case 3:
                  context.font = "44px Arial bolder";
                  (xt = 625), (xb = 585);
                  break;
                case 4:
                  context.font = "40px Arial bolder";
                  (xt = 620), (xb = 580);
                  break;
                case 5:
                  context.font = "32px Arial bolder";
                  (xt = 615), (xb = 575);
                  break;
                default:
                  context.font = "32px Arial bolder";
                  (xt = 620), (xb = 580);
                  break;
              }
              context.fillText(total, xt, 460);

              // 绘制回执内分数
              context.fillText(total, xb, 940);
              that.imgs.push(canvas.toDataURL("image/jpg"));

              if (that.imgs.length === arrays.length) {
                resolve();
              }
            }
          };
        }
      });
    },

    // 调用打印图片
    async handlePrintImage(roomId) {
      await this.getExportDatas(roomId);

      // this.drawImg(() => {
      //   printJS({
      //     // 要打印的内容 放在了data里
      //     printable: this.imgs,
      //     // 打印类型 目前为图片样式 可以根据上面的网址进行修改
      //     type: "image",
      //   });
      // });
    },


    // 打印学生打的互评明细
    async handlePrintMutualDetails(refs,notify){
      // console.log('refs',refs)
      // refs = [refs[0]]
      //执行代码
      this.domToImgs = [];
      for(let i in refs){
        let canvas = await html2canvas(refs[i], {
          useCORS: true , taintTest: true, logging:true,
          width: refs[i].clientWidth, //dom 原始宽度
          height: refs[i].clientHeight,
          scrollY: 0,
          scrollX: 0,
        });
        const url = canvas.toDataURL();
        this.domToImgs.push(url)
      }
      this.loading = false
      printJS({
        // 要打印的内容 放在了data里
        printable: this.domToImgs,
        // 打印类型 目前为图片样式 可以根据上面的网址进行修改
        type: "image",
        imageStyle: `display: block;margin: 0 auto;page-break-after: always;max-width:100%`
      });
      notify.close();       // 关闭警告框
      this.domToImgs = [];
    },
  },
};
