<!--
 * ======================================
 * 说明： 查分
 * 作者： Silence
 * 文件： checkScores.vue
 * 日期： 2023/6/26 21:28
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    :title="`【${className}${studentInfo.username}】得分情况`"
    :fullscreen="true"
    v-model="visible"
  >
    <el-tabs
      v-loading="loading"
      type="border-card"
      class="check-scores-tabs h-full"
      v-model="activeName"
    >
      <el-tab-pane
        label="自评与互评"
        name="student"
        class="h-full student-table"
      >
        <Main
          class="h-full"
          ref="main"
          :is-search="false"
          :is-action="false"
          :is-page="false"
          :on-params="onParams"
          :columns="stutendColumns"
          :is-selection="false"
          :is-index="false"
          :tableData="exportStudentData"
        >
          <template #table-item-学号="{ row }">
            <div
              style="height: 34px; width: 100%; padding: 8px 5px"
              class="flex items-center justify-start"
            >
              {{ row["学号"].score }}
            </div>
          </template>
          <template #table-item-姓名="{ row }">
            <div
              style="height: 34px; width: 100%; padding: 8px 5px"
              class="flex items-center justify-start"
            >
              {{ row["姓名"].score }}
            </div>
          </template>
          <template
            v-for="(name, index) in slotName"
            :slot="'table-item-' + name"
            slot-scope="{ row, $index }"
          >
            <div v-if="!row[name].id">
              {{ row[name].score }}
            </div>
            <div
              v-else
              class="h-full w-full"
              style="padding: 8px 5px; cursor: pointer"
              @click="openEditInput($index, 2 + index)"
            >
              <el-input
                :ref="$index + '' + (2 + index)"
                @keyup.enter.native="
                  enterModityScore(row, name, $index, 2 + index)
                "
                v-show="
                  acitveColumnIndex[0] === $index &&
                  acitveColumnIndex[1] === index + 2
                "
                type="text"
                v-model="row[name].score"
                :index="index"
                style="height: 34px"
              />
              <div
                style="height: 34px; width: 100%"
                class="flex items-center justify-start"
                v-show="
                  !(
                    acitveColumnIndex[0] === $index &&
                    acitveColumnIndex[1] === index + 2
                  )
                "
              >
                {{ row[name].score }}
              </div>
            </div>
          </template>
        </Main>
      </el-tab-pane>
      <el-tab-pane label="师评" name="teacher" class="h-full">
        <Main
          class="h-full"
          ref="main"
          :height="540"
          :is-search="false"
          :is-action="false"
          :is-page="false"
          :on-params="onParams"
          :columns="teacherColumns"
          :is-selection="false"
          :is-index="false"
          :tableData="exportTeacherData"
        >
        </Main>
      </el-tab-pane>
    </el-tabs>

    <template #footer>
      <el-button @click="visible = false">关 闭</el-button>
      <el-button type="primary" @click="handleExport()">导 出</el-button>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
import Main from "@/pages/components/main/index.vue";
export default {
  name: "",
  components: { Dialog, Main },
  props: {
    roomId: [String, Number],
    className: String,
  },
  directives: {
    //注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector("input").focus();
      },
    },
  },
  data() {
    return {
      tableDataBacks: [], // 表格数据备份  用于修改学生互评自评分数失败时，进行数据恢复
      acitveColumnIndex: [null, null], // X , Y
      slotName: [],
      loading: false,
      activeName: "student",
      visible: false,
      studentInfo: {},
      exportStudentHeader: {}, // 自评互评导出表头
      exportStudentData: [], // 自评互评导出数据
      exportTeacherHeader: [
        "学号",
        "学生",
        "打分老师",
        "学科",
        "指标",
        "分值",
        "打分项",
      ], // 师评导出表头
      exportTeacherData: [], // 师评导出数据

      stutendColumns: [
        {
          field: "学号",
          label: "学号",
          align: "left",
          width: "auto,",
        },
        {
          field: "姓名",
          label: "姓名",
          align: "left",
          width: "auto,",
        },
      ],
      teacherColumns: [
        {
          field: "学号",
          label: "学号",
          align: "left",
          width: "auto,",
        },
        {
          field: "学生",
          label: "学生",
          align: "left",
          width: "auto,",
        },
        {
          field: "打分老师",
          label: "打分老师",
          align: "left",
          width: "auto,",
        },
        {
          field: "学科",
          label: "学科",
          align: "left",
          width: "auto,",
        },
        {
          field: "指标",
          label: "指标",
          align: "left",
          width: "auto,",
        },
        {
          field: "分值",
          label: "分值",
          align: "left",
          width: "auto,",
        },
        {
          field: "打分项",
          label: "打分项",
          align: "left",
          width: "auto,",
        },
        {
          field: "打分项",
          label: "打分项",
          align: "left",
          width: "auto,",
        },
      ],
    };
  },
  watch: {
    exportStudentHeader: {
      deep: true,
      handler(val) {
        (this.stutendColumns = [
          {
            field: "学号",
            label: "学号",
            align: "left",
            width: "auto,",
          },
          {
            field: "姓名",
            label: "姓名",
            align: "left",
            width: "auto,",
          },
        ]),
          Object.values(val).map((value) => {
            if (!["学号", "姓名"].includes(value)) {
              this.slotName.push(value);
              this.stutendColumns.push({
                field: value,
                label: value,
                align: "left",
                width: "auto,",
              });
            }
          });
      },
    },
  },
  methods: {
    onParams() {},
    async open(data) {
      this.visible = true;
      this.studentInfo = data["row"];
      await this.getTeachersLog(); // 获取教师评分记录
      await this.getMutuallyLog(); // 获取互评记录
      await this.getSelfLog(); // 获取自评记录
    },

    openEditInput(rowIndex, columnIndex) {
      this.acitveColumnIndex = [rowIndex, columnIndex];
      this.$nextTick(() => {
        this.$refs[rowIndex + "" + columnIndex][0].focus(); // 自动获得焦点
      });
    },

    enterModityScore(row, name, rowIndex) {
      console.log("row:", row, name);
      let params = {
        roomId: this.roomId,
        fromStudentId: row.name_id,
        list: [
          {
            id: row[name].id,
            score: row[name].score,
          },
        ],
      };
      this.loading = true;
      this.$cloud
        .post("record/master_edit_student", params)
        .then(() => {
          this.loading = false;
          this.acitveColumnIndex = [null, null];
          this.$message.success("修改分数成功");
        })
        .catch((err) => {
          // console.log('备份数据',this.tableDataBacks[rowIndex][name]);
          // console.log('修改数据：',row[name]);
          // 修改失败时  将备份恢复到列表中
          this.$set(
            this.exportStudentData[rowIndex],
            name,
            this.tableDataBacks[rowIndex][name]
          );
          this.loading = false;
          this.acitveColumnIndex = [null, null];
          this.$message.error(err);
        });

      console.log("修改的数据：", row);
    },

    async getTeachersLog() {
      this.loading = true;
      let res = await this.$cloud.post("record/teacher", {
        roomId: this.roomId,
        from: "teacher",
        student_id: this.studentInfo.id,
      });

      if (res) {
        this.exportTeacherData = [];
        res.map((item) => {
          this.exportTeacherData.push({
            学号: item.sno,
            学生: item.student,
            打分老师: item.teacher,
            学科: item.name,
            指标: item.no,
            // 分值: Number(item.score).toFixed(2),
            分值: item.score,
            打分项: item.indicator,
          });
        });
      }
      this.loading = false;
    },

    // 自评数据
    async getSelfLog() {
      this.loading = true;
      let res = await this.$cloud.post("record/record", {
        roomId: this.roomId,
        from: "self",
        student_id: this.studentInfo.id,
      });
      if (res) {
        
        let fileds = Object.keys(this.exportStudentHeader);
        let ret = res.retList.map((dataItem) => {
          let obj = {};
          fileds.map((filedsKey) => {
            let value = {}
            Object.keys(dataItem).find((dataKey) =>{
              if(filedsKey.includes(dataKey)){
                value = {
                  score: dataItem[dataKey] || '',
                  id: dataItem[dataKey + "_id"] || '',
                }
              }
            });
            obj[this.exportStudentHeader[filedsKey]] = value
          });
          return {
            ...obj,
            ...dataItem,
          };
        });
        
        // let ret = res.retList.map((item) => {
        //   Object.keys(item).map((key) => {
        //     for (let i in this.exportStudentHeader) {
        //       if (i.indexOf(key) !== -1) {
        //         // item[this.exportStudentHeader[i]] = ['学号','姓名'].includes(this.exportStudentHeader[i]) ? item[key] : Number(item[key]).toFixed(2);
        //         // item[this.exportStudentHeader[i]] = item[key]
        //         item[this.exportStudentHeader[i]] = {
        //           score: item[key],
        //           id: item[key + "_id"],
        //         };
        //         delete item[key];
        //       }
        //     }
        //   });
        //   return item;
        // });
        this.exportStudentData.unshift(...ret);

        this.tableDataBacks = JSON.parse(
          JSON.stringify(this.exportStudentData)
        );
      }
      this.loading = false;
    },
    // 互评数据
    async getMutuallyLog() {
      this.loading = true;
      let res = await this.$cloud.post("record/record", {
        roomId: this.roomId,
        from: "student",
        student_id: this.studentInfo.id,
      });
      if (res) {
        let headler = {
          sno: "学号",
          username: "姓名",
        };
        let tableHeader = res.tableHeader;
        for (let key in tableHeader) {
          if (Object.keys(headler).length) {
            let old = "";
            for (let headlerKey in headler) {
              if (tableHeader[key] === headler[headlerKey]) {
                old = headlerKey;
              }
            }
            if (old) {
              headler[old + key] = tableHeader[key];
              delete headler[old];
            } else {
              this.$set(headler, key, tableHeader[key]);
            }
          } else {
            headler[key] = tableHeader[key];
          }
        }

        console.log("headler:", headler, res.retList);
        let fileds = Object.keys(headler);
        let ret = res.retList.map((dataItem) => {
          let obj = {};
          fileds.map((filedsKey) => {
            let value = {}
            Object.keys(dataItem).find((dataKey) =>{
              if(filedsKey.includes(dataKey)){
                value = {
                  score: dataItem[dataKey] || '',
                  id: dataItem[dataKey + "_id"] || '',
                }
              }
            });
            obj[headler[filedsKey]] = value
          });
          return {
            ...obj,
            ...dataItem,
          };
        });
        console.log('ret::',ret)
        
        // let ret = res.retList.map((item) => {
        //   Object.keys(item).map((key) => {
        //     for (let i in headler) {
        //           if (i.indexOf(key) !== -1) {
        //             // item[headler[i]] = ['学号','姓名'].includes(headler[i]) ? item[key] : Number(item[key]).toFixed(2);
        //             item[headler[i]] = {
        //               score: item[key],
        //               id: item[key + "_id"],
        //             };
        //             delete item[key];
        //           }
        //     }
        //   });
        //   return item;
        // });
        this.exportStudentHeader = headler;
        this.exportStudentData = ret;
      }
      this.loading = false;
    },

    // 导出
    handleExport() {
      this.$confirm("确定导出评分明细吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 数据
        this.exportStudentData = this.exportStudentData.map((item, index) => {
          if (index === 0) {
            item["评价类型"] = "自评";
          } else {
            item["评价类型"] = "互评";
          }

          return item;
        });
        let s_headler = Object.values(this.exportStudentHeader);
        s_headler.push("评价类型");
        let data = {
          自评与互评: this.exportStudentData,
          师评: this.exportTeacherData,
        };
        let columnHeaders = {
          自评与互评: s_headler,
          师评: this.exportTeacherHeader,
        };
        this.outputXlsxFile(
          data,
          [{ wch: 50 }, { wch: 50 }, { wch: 10 }],
          `${this.className}${this.studentInfo.username}得分情况`,
          columnHeaders
        );
      });
    },

    outputXlsxFile(data, wscols, xlsxName, columnHeaders) {
      let sheetNames = [];
      let sheetsList = {};
      const wb = XLSX.utils.book_new();

      for (let key in data) {
        sheetNames.push(key);
        let columnHeader = columnHeaders[key]; // 此处是每个sheet的表头
        let temp = this.transferData(data[key], columnHeader);
        sheetsList[key] = XLSX.utils.aoa_to_sheet(temp);
        sheetsList[key]["!cols"] = wscols;
      }

      wb["SheetNames"] = sheetNames;
      wb["Sheets"] = sheetsList;

      XLSX.writeFile(wb, xlsxName + ".xlsx");
    },

    transferData(data, columnHeader) {
      let content = [];
      content.push(columnHeader);
      data.forEach((item, index) => {
        let arr = [];
        columnHeader.map((column) => {
          arr.push(item[column]);
        });
        content.push(arr);
      });
      return content;
    },
  },
};
</script>
<style lang="scss" scoped>
.check-scores-tabs {
  ::v-deep .el-tabs__content {
    height: calc(100% - 40px);
  }
  ::v-deep .student-table .el-table__body-wrapper .el-table__cell {
    padding: 0 !important;
    .cell {
      padding: 0 !important;
    }
  }
}
</style>
