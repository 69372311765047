export const columns = [
  {
    field: "no",
    label: "学号",
    align: "left",
    width: "auto,",
    fixed: "left",
  },
  {
    field: "username",
    label: "姓名",
    align: "left",
    width: "auto,",
    fixed: "left",
  },
  {
    field: "group",
    label: "分组",
    align: "left",
    width: "auto,",
    fixed: "right",
  },
  {
    field: "rate",
    label: "评分",
    align: "center",
    width: "120",
    fixed: "right",
    rate: "YES",
  },
  {
    field: "hasEval",
    label: "评分状态",
    align: "center",
    width: "120",
    fixed: "right",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "180",
    fixed: "right",
  },
];

export const searchOptions = [
  {
    type: "input",
    field: "no",
    label: "学号",
    placeholder: "学号查询",
  },
  {
    type: "input",
    field: "name",
    label: "姓名",
    placeholder: "姓名查询",
  },
  // {
  //   type: "select",
  //   field: "gender",
  //   label: "性别",
  //   multiple: false,
  //   placeholder: "选择性别",
  //   options: [
  //     {
  //       id: "",
  //       name: "全部",
  //     },
  //     {
  //       id: 'MALE',
  //       name: "男",
  //     },
  //     {
  //       id: 'FEMALE',
  //       name: "女",
  //     },
  //   ],
  // },
];
