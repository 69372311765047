<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-04-30 00:44:46
 * @LastEditors:
 * @LastEditTime: 2023-04-30 00:44:46
-->
<template>
  <Dialog
    title="综合素质评价"
    width="1200px"
    height="90%"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <div
      style="overflow: hidden; overflow-y: auto; height: 100%"
      v-loading="loading"
    >
      <div
        style="overflow: hidden; overflow-y: auto"
        class="m-1 border border-solid bg-white"
        ref="viewsTable"
      >
        <div class="text-center p-2 border-solid border-b font-bold">
          {{ result["name"] }}
        </div>
        <div class="p-2 border-solid border-b">
          学号：{{ userinfo["no"] }} 姓名：{{ userinfo["username"] }}
        </div>
        <el-row class="border-solid border-b">
          <el-col class="text-center p-2 border-solid border-r" :span="3"
            >评价维度</el-col
          >
          <el-col class="text-center p-2 border-solid border-r" :span="4"
            >评价维度</el-col
          >
          <el-col class="text-center p-2 border-solid border-r" :span="3"
            >学生自评</el-col
          >
          <el-col class="text-center p-2 border-solid border-r" :span="4"
            >自评小计</el-col
          >
          <el-col class="text-center p-2 border-solid border-r" :span="4"
            >学生互评分</el-col
          >
          <el-col class="text-center p-2 border-solid border-r" :span="3"
            >教师评议分</el-col
          >
          <el-col class="text-center p-2 border-solid" :span="3">总分</el-col>
        </el-row>
        <el-row
          v-for="(item, index) in result['configData']"
          class="border-solid border-b flex flex-wrap"
          :key="index"
        >
          <el-col
            class="
              text-center
              p-2
              border-solid border-r
              flex
              items-center
              justify-center
            "
            :span="3"
            >{{ item["dimension"].name }}</el-col
          >
          <el-col class="text-center border-solid border-r" :span="4">
            <div
              v-for="(part, partIndex) in item['indicator']"
              :key="partIndex"
              :class="`${
                partIndex < item['indicator'].length - 1
                  ? 'border-solid border-b'
                  : ''
              } h-12 flex justify-center items-center`"
            >
              {{ part["name"] }}（{{ part["score"] }}分）
            </div>
          </el-col>
          <el-col class="text-center border-solid border-r" :span="3">
            <div
              v-for="(part, partIndex) in item['indicator']"
              :key="partIndex"
              :class="`${
                partIndex < item['indicator'].length - 1
                  ? 'border-solid border-b'
                  : ''
              } h-12 px-2 flex justify-center items-center`"
            >
              <el-input-number
                v-if="isEdit"
                class="w-full"
                :controls="false"
                v-model="part.self_score"
              ></el-input-number>
              <span v-else>{{ part.self_score }}</span>
            </div>
          </el-col>
          <el-col
            class="
              text-center
              p-2
              border-solid border-r
              flex
              items-center
              justify-center
            "
            :span="4"
          >
            <el-input-number
              v-if="isEdit"
              class="w-full"
              :controls="false"
              v-model="item.selfTotal"
            ></el-input-number>
            <span v-else>{{ item.selfTotal }}</span>
          </el-col>
          <el-col class="text-center border-solid border-r" :span="4">
            <div
              v-for="(part, partIndex) in item['indicator']"
              :key="partIndex"
              :class="`${
                partIndex < item['indicator'].length - 1
                  ? 'border-solid border-b'
                  : ''
              } h-12 px-2 flex justify-center items-center`"
            >
              <el-input-number
                v-if="isEdit"
                class="w-full"
                :controls="false"
                v-model="part.student_score"
              ></el-input-number>
              <span v-else>{{ part.student_score }}</span>
            </div>
          </el-col>
          <el-col class="text-center border-solid border-r" :span="3">
            <div
              v-for="(part, partIndex) in item['indicator']"
              :key="partIndex"
              :class="`${
                partIndex < item['indicator'].length - 1
                  ? 'border-solid border-b'
                  : ''
              } h-12 px-2 flex justify-center items-center`"
            >
              <el-input-number
                v-if="isEdit"
                class="w-full"
                :controls="false"
                v-model="part.teacher_score"
              ></el-input-number>
              <span v-else>{{ part.teacher_score }}</span>
            </div>
          </el-col>
          <el-col
            class="
              text-center
              p-2
              border-solid
              flex
              items-center
              justify-center
            "
            :span="3"
          >
            <el-input-number
              v-if="isEdit"
              class="w-full"
              :controls="false"
              v-model="item.total"
            ></el-input-number>
            <span v-else>{{ item.total }}</span>
          </el-col>
        </el-row>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
import html2canvas from "html2canvas";
export default {
  name: "",
  computed: {},
  components: { Dialog },
  props: {},
  data() {
    return {
      loading: false,
      visible: false,
      isEdit: false,
      userinfo: {},
      result: {
        name: "",
      },
      resultDatas: {},
    };
  },
  created() {},
  mounted() {},
  methods: {
    async open(info) {
      this.visible = true;
      this.userinfo = info;
      await this.getResultConfig(); // 获取汇总成绩表
    },

    //获取汇总配置
    async getResultConfig() {
      this.loading = true;
      //id = 1 综合素质评价学生“自评+互评”表
      await this.$cloud
        .get("/evaluation/detail", { id: 1 })
        .then(async (res) => {
          this.loading = false;
          this.result = res;
          await this.getResult();
        })
        .catch((err) => {
          this.$message.error(err)
          this.loading = false;
        });
    },

    //获取汇总结果
    async getResult() {
      this.loading = true;
      await this.$cloud
        .get("/evaluation/report", {
          studentId: this.userinfo.id,
          evaluationId: 1,
        })
        .then((res) => {
          this.loading = false;
          if (
            !!this.result.configData &&
            typeof this.result.configData == "object" &&
            !!this.result.configData.length
          ) {
            this.result.configData.map((item) => {
              let total = 0,
                selfTotal = 0;
              item.indicator.map((part) => {
                let find = res.find(
                  (option) => option.indicator_id === part.id
                );
                let arrays = ["self_score", "student_score", "teacher_score"];
                arrays.forEach((key) => {
                  part[key] = find[key];
                  total += parseFloat(find[key]);
                });
                selfTotal += parseFloat(find["self_score"]);
              });
              item["total"] = isNaN(total) ? 0 : total.toFixed(2);
              item["selfTotal"] = isNaN(selfTotal) ? 0 : selfTotal.toFixed(2);
            });
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.loading = false;
        });
    },

    // 确定
    handleConfirm() {
      const viewsTable = this.$refs["viewsTable"];
      html2canvas(viewsTable, {
        height: this.$refs["viewsTable"].clientHeight,
      }).then((canvas) => {
        const image = canvas.toDataURL();
        let aLink = document.createElement("a");
        aLink.download = this.result["name"]; //这里写保存时的图片名称
        aLink.href = image;
        aLink.click();
      }).catch(()=>{
        this.$message.error('网络异常！')
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
